<template>
  <div id="app">
    <div class="settings" v-show="active == 'settings'">
      <div class="form">
        <div class="times">
          <vs-input
            type="time"
            v-model="start"
            label-placeholder="上班"
            primary
          >
            <template #icon>
              <i class='bx bx-time'></i>
            </template>
          </vs-input>
          <span>-</span>
          <vs-input
            type="time"
            v-model="end"
            label-placeholder="下班"
            primary
          >
            <template #icon>
              <i class='bx bx-time-five'></i>
            </template>
          </vs-input>
        </div>
        <br>
        <br>
        <vs-input
          class="money-imput"
          type="number"
          v-model="money"
          label-placeholder="税后工资/小时"
          primary
          min="0"
        >
          <template #icon>
            <i class='bx bx-pound'></i>
          </template>
        </vs-input>
        <br>
        <br>
        <div class="times">
          <div class="color-picker">
            <div id="color-picker-1"></div> <span>前景色</span>
          </div>
          <div class="color-picker">
            <div id="color-picker-2"></div> <span>背景色</span>
          </div>
        </div>
      </div>
    </div>
    <div class="count" v-show="active == 'count'">
      <div class="today-tip">今天已经赚了</div>
      <div class="big-number">{{ nowCount }}</div>
      <div class="cny-tip" v-if="state !== 'on' || (state === 'on' && rateLoad)"><span v-if="state === 'pending'">先休息下</span><span v-else>大概就是 ¥{{ nowCountCny }} 这么多</span></div>
    </div>
    <div class="moyu" v-show="active == 'moyu'">
      <div class="today-tip">今天的班已经上了</div>
      <div class="big-number-off">{{ off }}%</div>
      <div class="cny-tip"><span v-if="state === 'on'">马上就下班</span><span v-if="state === 'pending'">先休息下</span><span v-if="state === 'off'">摸大鱼时间</span></div>
    </div>
    <div class="moyu-bar" v-show="active == 'moyu'">
      <div class="bar">
        <div class="bar-fill" :style="{ width: `${off}%` }"></div>
      </div>
    </div>
    <vs-navbar center-collapsed v-model="active" :class="{ color: active !== 'settings' }">
      <vs-navbar-item :active="active == 'settings'" id="settings">
        设置
      </vs-navbar-item>
      <vs-navbar-item :active="active == 'count'" id="count">
        工资
      </vs-navbar-item>
      <vs-navbar-item :active="active == 'moyu'" id="moyu">
        下班
      </vs-navbar-item>
    </vs-navbar>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuesax from 'vuesax'
import Pickr from '@simonwep/pickr'

import '@simonwep/pickr/dist/themes/nano.min.css'
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax)

let rate = 0

export default {
  name: 'App',
  data () {
    return {
      bgColor: '30, 32, 35',
      fontColor: '#efadad',
      money: '20',
      start: '09:00',
      end: '17:00',
      active: 'count',
      nowCount: '0.0000',
      nowCountCny: '0.0000',
      off: '0.00000',
      rateLoad: false,
      state: 'on'
    }
  },
  watch: {
    bgColor () {
      const config = JSON.parse(localStorage.getItem('gzc_config')) || {}
      config.bgColor = this.bgColor
      localStorage.setItem('gzc_config', JSON.stringify(config))
    },
    fontColor () {
      const config = JSON.parse(localStorage.getItem('gzc_config')) || {}
      config.fontColor = this.fontColor
      localStorage.setItem('gzc_config', JSON.stringify(config))
    },
    money () {
      const config = JSON.parse(localStorage.getItem('gzc_config')) || {}
      config.money = this.money
      localStorage.setItem('gzc_config', JSON.stringify(config))
    },
    start () {
      const config = JSON.parse(localStorage.getItem('gzc_config')) || {}
      config.start = this.start
      localStorage.setItem('gzc_config', JSON.stringify(config))
    },
    end () {
      const config = JSON.parse(localStorage.getItem('gzc_config')) || {}
      config.end = this.end
      localStorage.setItem('gzc_config', JSON.stringify(config))
    }
  },
  methods: {
    toRGB (hex) {
      const rgb = hex.replace('#', '').match(/.{2}/g).map(x => parseInt(x, 16))
      return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`
    },
    calcHour () {
      const today = new Date()
      const start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), this.start.split(':')[0], this.start.split(':')[1])
      let end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), this.end.split(':')[0], this.end.split(':')[1])

      if (end.valueOf() <= start.valueOf()) {
        end += 24 * 60 * 60 * 1000
      }

      if (today.valueOf() > end.valueOf()) {
        const count = (((end - start) / (60 * 60 * 1000)) * parseFloat(this.money))
        this.nowCount = count.toFixed(5)
        this.nowCountCny = (count * rate).toFixed(4)
        this.off = '100.00000'
        this.state = 'off'
      } else if (today.valueOf() < start.valueOf()) {
        this.nowCount = '0.0000'
        this.nowCountCny = '0.0000'
        this.off = '0.00000'
        this.state = 'pending'
      } else {
        const count = (((today - start) / (60 * 60 * 1000)) * parseFloat(this.money))
        this.nowCount = count.toFixed(4)
        this.nowCountCny = (count * rate).toFixed(4)
        this.off = (((today - start) / (end - start)) * 100).toFixed(5)
        this.state = 'on'
      }
    }
  },
  mounted () {
    if (!localStorage.getItem('gzc_config')) {
      localStorage.setItem('gzc_config', '{"money":"15","start":"09:00","end":"17:00","target":"0","bgColor":"#003B68","fontColor":"#efadad"}')
    }

    const config = JSON.parse(localStorage.getItem('gzc_config')) || {}

    this.money = config.money
    this.start = config.start
    this.end = config.end
    this.bgColor = config.bgColor
    this.fontColor = config.fontColor
    document.documentElement.style = `--font: ${this.toRGB(this.fontColor)}`
    document.body.style = `--background: ${this.bgColor}`

    Pickr.create({
      el: '#color-picker-1',
      theme: 'nano',
      comparison: false,
      default: config.fontColor,
      components: {
        palette: false,
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: false
        }
      }
    }).on('change', (color, instance) => {
      this.fontColor = `#${color.toHEXA().join('')}`
      document.documentElement.style = `--font: ${this.toRGB(this.fontColor)}`
    })
    Pickr.create({
      el: '#color-picker-2',
      theme: 'nano',
      comparison: false,
      default: config.bgColor,
      components: {
        palette: false,
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: false
        }
      }
    }).on('change', (color, instance) => {
      this.bgColor = `#${color.toHEXA().join('')}`
      document.body.style = `--background: ${this.bgColor}`
    })

    fetch('https://exchangerate-api.p.rapidapi.com/rapid/latest/GBP', {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '0a1fa762e2msh5dab7a1b0dd2f4fp13e775jsn6587f0422ff4',
        'X-RapidAPI-Host': 'exchangerate-api.p.rapidapi.com'
      }
    }).then(response => response.json()).then(response => {
      rate = response.rates.CNY
      this.rateLoad = true
    })

    const frameCb = () => {
      this.calcHour()
      requestAnimationFrame(() => {
        frameCb()
      })
    }

    frameCb()
  }
}
</script>

<style lang="less">
[vs-theme=dark] {
  --vs-text: 255, 255, 255;
  --vs-gray-1: 24, 25, 28;
  --vs-gray-2: 20, 20, 23;
  --vs-gray-3: 15, 16, 19;
  --vs-gray-4: 10, 11, 14;
  --vs-shadow-opacity: .3;
  --vs-dark: 0, 0, 0;
  --vs-background-opacity: .6;
  background-color: rgba(var(--vs-background), 1);
  color: rgba(var(--vs-text), 1);
}

body {
  margin: 0;
  padding: 0;
  -moz-user-select: none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
#app, .settings, .count, .moyu, .moyu-bar {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.count, .moyu, .moyu-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(var(--font));
}
.count, .moyu {
  background-color: var(--background);
}
.bar {
  width: 90%;
  height: 3vw;
  background-color: var(--background);
  border-radius: 40px;
  border: 2px solid rgba(var(--font), 1);
  overflow: hidden;
  .bar-fill {
    height: 100%;
    background-color: rgba(var(--font), 1);
  }
}
.pickr {
  &:focus {
    outline: none;
  }
  button {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.pcr-app {
  background-color: #2b2b2b;
  .pcr-color-preview {
    background-color: #2b2b2b;
  }
  .pcr-result {
    background-color: rgb(30, 32, 35)!important;
    color: white!important;
  }
}
.pcr-color-chooser {
  margin-top: .8rem!important;
}
.pcr-swatches {
  display: none!important;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-palette .pcr-palette {
  border-radius: 0.15rem 0.15rem 0 0;
}
.vs-navbar-content {
  background-color: rgba(255, 255, 255, 10%);
  .color {
    color: rgba(var(--font), 1);
    background-color: rgba(var(--font), 10%);
  }
}
.color.vs-navbar-content {
  color: rgba(var(--font), 1);
  background-color: rgba(var(--font), 10%);
}
.settings {
  background-color: #1E2023!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}
.form {
  width: 85%;
  max-width: 500px;
}
.vs-input--has-icon ~ .vs-input__label {
  top: -3px;
}
.times {
  display: flex;
  & > span {
    margin: 0 1rem;
    margin-top: .4rem;
    display: inline-block;
    width: 8px;
  }
  .vs-input-parent, .color-picker {
    width: calc(50% - 4px - 1rem);
  }
  .vs-input {
    -webkit-appearance: none;
    width: 100%;
  }
}
.money-imput {
  .vs-input {
    width: 100%;
  }
}
.color-picker {
  display:flex;
  align-items: center;
  margin-bottom: 1rem;
  & > span {
    margin-top: 2px;
  }
  & > div {
    margin-right: 1rem;
  }
  & > div > button:after {
    border: 2px solid #fff;
  }
}
.big-number {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 29vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &::before {
    content: '￡';
    position: absolute;
    margin-top: 4vw;
    font-size: 35vw;
    opacity: 0.3;
    font-weight: bold;
    line-height: 0;
  }
}
.big-number-off {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 22vw;
  opacity: 0.4;
}
.today-tip {
  font-size: ~"max(22px, 3vw)";
  opacity: .5;
}
.cny-tip {
  font-size: ~"max(22px, 3vw)";
  margin-top: -2vw;
  opacity: .5;
  font-variant-numeric: tabular-nums;
}
.vs-navbar {
  height: 60px;
  .vs-navbar__item {
    padding: 15px 20px;
  }
}
</style>
